import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import { navigate } from 'gatsby-link';
import Helmet from 'react-helmet';
import Constants from '../../static/Constants';
import { useTranslation } from 'react-i18next';
import getHeaderLInks from '../shared/headerLinks';
import QuickLinks from '../components/QuickLinks';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Card } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import getStaticImageURL from '../shared/getStaticImageURL';
import ReactPlayer from 'react-player';
import {
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
} from 'recharts';

const About = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState('en');
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (i18n.languages.includes(i18n.language)) {
      setCurrentLang(i18n.language);
    }
  }, [i18n.language]);

  const { pageContext } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const programLink = pageContext.programUrl;

  const { aboutData, programName } = (data || {}).node || {};
  const { pageHeader, headerLogo, headerLogoScroll, screenName } =
    (data || {}).node || {};

  const headerLinks = getHeaderLInks('About', programLink, data.noLinks);

  const logos = pageContext.logos || [];
  const footerLogos = pageContext.footerLogos || [];

  const footerLinks = [
    {
      name: 'FAQ',
      link: programLink + '/faq',
    },
    {
      name: 'About',
      link: programLink + '/about',
    },
    {
      name: 'Contact Us',
      link: programLink + '/contactUs',
    },
    {
      name: 'Disclaimer',
      link: programLink + '/disclaimer',
    },
  ];

  let videosSrc = {
    en: [
      {
        src: 'https://www.youtube.com/embed/h4ZNhEW68AY',
        desc:
          'Learn about the Invest-in-ACP Portal and discover the full functionalities of this portal by watching this video.',
      },
      {
        src: 'https://www.youtube.com/embed/aqmwMtsECik',
        desc:
          'Learn how the Digital Investment Profiling System (DIPS) constitutes the back-end of the Invest-in-ACP Portal',
      },
    ],
    fr: [
      {
        src: 'https://www.youtube.com/embed/Yk8tSCxCOHg',
        desc:
          'Découvrez le portail Invest-in-ACP et découvrez toutes les fonctionnalités de ce portail en visionnant cette vidéo.',
      },
      {
        src: 'https://www.youtube.com/embed/vKIXokeuiGc',
        desc:
          'Découvrez comment le système de profilage numérique des investissements (DIPS) constitue le back-end du portail Invest-in-ACP.',
      },
    ],
  };

  let vidoesRefs = [useRef(null), useRef(null)];

  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.videoId
    ) {
      vidoesRefs[parseInt(props.location.state.videoId)].current.src =
        videosSrc[i18n.language][parseInt(props.location.state.videoId)] +
        '?autoplay=1';
      vidoesRefs[
        parseInt(props.location.state.videoId)
      ].current.scrollIntoView();
    }
  }, [props.location]);

  const helmetLink = `${Constants.appUrl}/about`;

  const linearData = [
    { year: '2001', value: 4.3 },
    { year: '2002', value: 6.3 },
    { year: '2003', value: 4.1 },
    { year: '2004', value: 6.6 },
    { year: '2005', value: 6 },
    { year: '2006', value: 6.1 },
    { year: '2007', value: 6.1 },
    { year: '2008', value: 5.1 },
    { year: '2009', value: 3 },
    { year: '2010', value: 6 },
    { year: '2011', value: 4.2 },
    { year: '2012', value: 2.7 },
    { year: '2013', value: 5.1 },
    { year: '2014', value: 4.9 },
    { year: '2015', value: 2.8 },
    { year: '2016', value: 1.4 },
    { year: '2017', value: 2.5 },
    { year: '2018', value: 2.8 },
    { year: '2019', value: 2.6 },
    { year: '2020', value: -2.1 },
    { year: '2021', value: 4.2 },
  ];

  const CustomizedTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="chart-tooltip about-line-chart-tooltip">
          <div className="">
            <span>
              {t('sub_saharan_africa')}({label})
            </span>
          </div>
          <div className="line-value">
            <span>{payload[0].payload.value}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  // <p
  //                 dangerouslySetInnerHTML={{ __html: t('why_invest_in_acp_1') }}
  //               ></p>

  return (
    <Layout
      programLink={programLink}
      // title={t(`About ${programName || ''}`)}
      title={t('about_invest_acp')}
      headerLinks={headerLinks}
      footerLinks={footerLinks}
      logos={logos}
      footerLogos={footerLogos}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      imageName={'about_banner.jpg'}
      contentAlignmentStyle={'textCenter'}
      visitedLinks={[{ url: '/', key: 'home', name: 'Home' }]}
      currentPage={{ key: 'about' }}
      screenName={screenName}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('about_invest_acp')}</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>
      <div>
        <section id="program" className="invest-details">
          <div className="container">
            <GridContainer className="countries-contanier about-grid-cont">
              <GridItem md={6} sm={12} className="countinterv-details">
                <div className="about-contries-counts">
                  <div className="progress-bar">
                    <CircularProgress
                      variant="determinate"
                      value={(120 * 100) / 200}
                      className="blue"
                    />
                    <CircularProgress
                      variant="determinate"
                      value={100}
                      className="gray"
                    />
                    <span>1.2</span>
                  </div>
                  <div className="progress-details">
                    <p>{t('billion_people')}</p>
                    <span>{t('abt_ssa')}</span>
                  </div>
                </div>
                <div className="about-contries-counts">
                  <div className="progress-bar">
                    <CircularProgress
                      variant="determinate"
                      value={(41 * 100) / 200}
                      className="blue"
                    />
                    <CircularProgress
                      variant="determinate"
                      value={100}
                      className="gray"
                    />
                    <span>41</span>
                  </div>
                  <div className="progress-details">
                    <p>{t('million_people')}</p>
                    <span>{t('abt_carribean')}</span>
                  </div>
                </div>
                <div className="about-contries-counts">
                  <div className="progress-bar">
                    <CircularProgress
                      variant="determinate"
                      value={(14 * 100) / 200}
                      className="blue"
                    />
                    <CircularProgress
                      variant="determinate"
                      value={100}
                      className="gray"
                    />
                    <span>14</span>
                  </div>
                  <div className="progress-details">
                    <p>{t('million_people')}</p>
                    <span>{t('abt_pacific')}</span>
                  </div>
                </div>
              </GridItem>
              <GridItem md={6} sm={12} className="about-second-panel">
                <h3>{t('why_invest_acp')}</h3>
                <p
                  dangerouslySetInnerHTML={{ __html: t('invest_in_acp_bk1') }}
                ></p>
              </GridItem>
            </GridContainer>
            <GridContainer className="about-grid-cont">
              <GridItem md={6} sm={12} className="about-second-panel">
                <p
                  dangerouslySetInnerHTML={{ __html: t('invest_in_acp_bk2') }}
                ></p>
              </GridItem>

              <GridItem
                md={6}
                sm={12}
                className="about-first-panel about-first-panel-2"
              >
                <img
                  className="about-first-panel-image"
                  src={getStaticImageURL('about_image_before.jpg')}
                  alt="project-details"
                ></img>
                <img
                  className="about-second-panel-image"
                  src={getStaticImageURL('about_image_after.jpg')}
                  // src={require('../assets/img/about_image_after.jpg')}
                  alt="Invest in ACP"
                ></img>
                <div className="first-panel-stats">
                  <ul>
                    <li>
                      <h4>79.8</h4>
                      <p>{t('billion_eur_fdi')}</p>
                    </li>
                    <li>
                      <h4>833</h4>
                      <p>{t('billion_eur_fdi_2')}</p>
                    </li>
                  </ul>
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer className="about-grid-cont">
              <GridItem md={6} sm={12} className="about-first-panel">
                <ResponsiveContainer height={400}>
                  <LineChart data={linearData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="year"
                      padding={{ left: 30, right: 30 }}
                      // interval={1}
                    />

                    <YAxis label={'%'} />
                    <Tooltip content={<CustomizedTooltip />} />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#8884d8"
                      activeDot={{ r: 8 }}
                      name={t('sub_saharan_africa')}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </GridItem>
              <GridItem md={6} sm={12} className="about-second-panel">
                <p
                  dangerouslySetInnerHTML={{ __html: t('invest_in_acp_bk3') }}
                ></p>
              </GridItem>
            </GridContainer>

            <GridContainer className="about-grid-cont">
              <GridItem md={6} sm={12} className="about-second-panel">
                <p
                  dangerouslySetInnerHTML={{ __html: t('invest_in_acp_bk4') }}
                ></p>
              </GridItem>
              <GridItem md={6} sm={12} className="about-fourth-panel">
                <div className="about-stats">
                  <div className="contries-counts">
                    <div className="progress-bar">
                      <CircularProgress
                        variant="determinate"
                        value={13.7}
                        className="blue"
                      />
                      <CircularProgress
                        variant="determinate"
                        value={100}
                        className="gray"
                      />
                      <span>13.7%</span>
                    </div>
                    <div className="progress-details">
                      <p>{t('intra_acp_trade')}</p>
                    </div>
                  </div>
                  <div className="contries-counts">
                    <div className="progress-bar">
                      <CircularProgress
                        variant="determinate"
                        value={43}
                        className="blue"
                      />
                      <CircularProgress
                        variant="determinate"
                        value={100}
                        className="gray"
                      />
                      <span>43%</span>
                    </div>
                    <div className="progress-details">
                      <p>{t('shipping_cont_index')}</p>
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer className="about-grid-cont">
              <GridItem md={6} sm={12} className="about-second-panel">
                <Button
                  onClick={() => {
                    navigate(programLink + '/projects');
                  }}
                  className="btn-primary"
                  variant="contained"
                >
                  {t('discover_projects')}
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </section>
        <section id="project" className="project-details">
          <img
            className="project-details-image"
            src={getStaticImageURL('about_project_image.jpg')}
            alt="project-details"
          ></img>
          <div className="container about-project-container">
            <h3> {t('about_project')}</h3>
            <Card className="project-card">
              <GridContainer>
                {aboutData ? (
                  aboutData.map((aboutKey, index) => {
                    return index != 2 ? (
                      <GridItem key={aboutKey} md={6} sm={12}>
                        <p
                          dangerouslySetInnerHTML={{ __html: t(aboutKey) }}
                        ></p>
                      </GridItem>
                    ) : (
                      <></>
                    );
                  })
                ) : (
                  <p>{t('About Page Content')}</p>
                )}
              </GridContainer>
            </Card>
          </div>
        </section>

        <section
          id="flyer"
          className="flyer-details"
          style={{ paddingBottom: '0px' }}
        >
          <div className="container">
            <GridContainer className="flyer-container">
              <GridItem sm={4} className="left-section">
                <h3>{t('invest_in_acp_portal')}</h3>
                <img
                  src={getStaticImageURL('3b_logo6_blue_en.png')}
                  // src={require('../assets/img/en/3b_logo6_blue_en.png')}
                  alt="Investment Promotion Portal"
                ></img>
              </GridItem>
              <GridItem sm={8} className="right-section">
                <GridContainer className="right-section-row">
                  <GridItem className="item-logo" sm={3}>
                    <img
                      src={getStaticImageURL('vectoriel-5.png')}
                      // src={require('../assets/img/aboutbullets/vectoriel-5.png')}
                      alt=""
                    ></img>
                  </GridItem>
                  <GridItem className="item-desc" sm={9}>
                    <p>{t('about_pointer_1')}</p>
                  </GridItem>
                </GridContainer>

                <GridContainer className="right-section-row">
                  <GridItem className="item-logo" sm={3}>
                    <img
                      src={getStaticImageURL('vectoriel-4.png')}
                      // src={require('../assets/img/aboutbullets/vectoriel-4.png')}
                      alt=""
                    ></img>
                  </GridItem>
                  <GridItem className="item-desc" sm={9}>
                    <p>{t('about_pointer_2')}</p>
                  </GridItem>
                </GridContainer>

                <GridContainer className="right-section-row">
                  <GridItem className="item-logo" sm={3}>
                    <img
                      src={getStaticImageURL('vectoriel-3.png')}
                      // src={require('../assets/img/aboutbullets/vectoriel-3.png')}
                      alt=""
                    ></img>
                  </GridItem>
                  <GridItem className="item-desc" sm={9}>
                    <p>{t('about_pointer_3')}</p>
                  </GridItem>
                </GridContainer>

                <GridContainer className="right-section-row">
                  <GridItem className="item-logo" sm={3}>
                    <img
                      src={getStaticImageURL('vectoriel-2.png')}
                      // src={require('../assets/img/aboutbullets/vectoriel-2.png')}
                      alt=""
                    ></img>
                  </GridItem>
                  <GridItem className="item-desc" sm={9}>
                    <p>{t('about_pointer_4')}</p>
                  </GridItem>
                </GridContainer>

                <GridContainer className="right-section-row">
                  <GridItem className="item-logo" sm={3}>
                    <img
                      src={getStaticImageURL('vectoriel-1.png')}
                      // src={require('../assets/img/aboutbullets/vectoriel-1.png')}
                      alt=""
                    ></img>
                  </GridItem>
                  <GridItem className="item-desc" sm={9}>
                    <p>{t('about_pointer_5')}</p>
                  </GridItem>
                </GridContainer>

                <GridContainer className="last right-section-row">
                  <GridItem className="item-logo" sm={3}>
                    <img
                      src={getStaticImageURL('vectoriel.png')}
                      // src={require('../assets/img/aboutbullets/vectoriel.png')}
                      alt=""
                    ></img>
                  </GridItem>
                  <GridItem className="item-desc" sm={9}>
                    <p>{t('about_pointer_6')}</p>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>
        </section>
        <section
          id="flyer-2"
          className="flyer-objectives"
          style={{ paddingTop: '0px' }}
        >
          <img
            className="flyer-objectives-image"
            src={getStaticImageURL('Background.jpg')}
            alt="flyer-objectives"
          ></img>
          <div className="container objective-container">
            <div className="objectives-title">
              <p>{t('about_design_title')}:</p>
            </div>
            <div className="objectives-desc">
              <GridContainer>
                <GridItem
                  sm={2}
                  md={6}
                  style={
                    !mobileScreen
                      ? {
                          borderRight: '1px solid #fff',
                        }
                      : {}
                  }
                >
                  <p>{t('about_design_desc_1')}</p>
                </GridItem>
                {mobileScreen ? <hr className="divider"></hr> : ''}
                <GridItem sm={2} md={6}>
                  <p>{t('about_design_desc_2')}</p>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </section>
        <section id="project" className="project-details">
          <img
            className="project-details-image"
            src={getStaticImageURL('about_project_image.jpg')}
            alt="project-details"
          ></img>
          <div className="container about-project-container">
            <h3> {t('about_explore')}</h3>
            <Card className="project-card">
              <GridContainer>
                {aboutData ? (
                  aboutData.map((aboutKey, index) => {
                    return index == 2 ? (
                      <GridItem key={aboutKey} md={12}>
                        <p
                          dangerouslySetInnerHTML={{ __html: t(aboutKey) }}
                        ></p>
                      </GridItem>
                    ) : (
                      <></>
                    );
                  })
                ) : (
                  <p>{t('About Page Content')}</p>
                )}
              </GridContainer>
            </Card>
          </div>
        </section>

        <section id="project" className="project-details">
          <img
            className="project-details-image"
            src={getStaticImageURL('about_project_image.jpg')}
            alt="project-details"
          ></img>
          <div className="container about-project-container">
            <h3>{t('testimonials')}</h3>
            {/* <Card className="project-card"> */}
            <ReactPlayer
              width="100%"
              height="500px"
              url={
                'https://youtube.com/playlist?list=PLxsAjXdoFvFS5tWZwR1z2qrV7Z5Kx_oov'
              }
              controls={true}
              style={{ borderRadius: '5px' }}
            />
            {/* </Card> */}
          </div>
        </section>
        <section id="video">
          <div className="container">
            {(videosSrc[currentLang] || []).map((video, index) => (
              <Card key={index} className="video-card">
                <iframe
                  width={'100%'}
                  height={'500px'}
                  src={video.src}
                  frameBorder="0"
                  // allow='encrypted-media'
                  title="video"
                  allow="autoplay"
                  allowFullScreen={true}
                  ref={vidoesRefs[index]}
                />
                <p>{video.desc}</p>
              </Card>
            ))}
          </div>
        </section>
        <section className="quik-links mb8">
          <img
            className="quik-links-image"
            src={getStaticImageURL('invention.jpg')}
            alt="quik-links"
          ></img>
          <div className="container">
            <QuickLinks programLink={programLink}></QuickLinks>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default About;

About.propTypes = {
  center: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object,
};
